<script>
import DataSource from '@maxsystems/xplat/core/data-source'
import { getAppraisalData } from '@maxsystems/xplat/core/routes/VDP/data-source'

import SRP from './SRP/index.vue'
import WidgetDetail from './VDP/components/WidgetDetail'

export const dataSource = new DataSource()
const AppraisalData = getAppraisalData(dataSource)
export const appraisalData = new AppraisalData()

export default {
  name: 'AppraisalDashboard',
  components: {
    SRP,
    WidgetDetail
  },
  provide: { dataSource, appraisalData },
  mounted () {
    if (this.$route.name === 'CustomerOffer') return
    dataSource.init()
  }
}
</script>

<template>
  <VContainer
    pa-0
    fill-height
    align-start
    fluid
  >
    <template v-if="$route.name === 'CustomerOffer'">
      <router-view />
    </template>
    <template v-else>
      <SRP />
      <router-view />
      <WidgetDetail />
    </template>
  </VContainer>
</template>
